
// 线索基础模块信息
import { defineComponent } from 'vue';
import useFilter from '@/hooks/useFilter';
export default defineComponent({
  name: 'clueBaseInfo',
  props: ['data'],
  setup() {
    const { empty } = useFilter();
    return { empty };
  },
});
