
import { httpPost } from '@/api';
import ChooseMan from '@/components/ChooseMan/ChooseMan.vue';
import CitySelect from '@/components/CitySelect/CitySelect.vue';
import FileContent from '@/components/FileContent/FileContent.vue';
import { ajaxLoading, errorMessage, loadPage, successMessage } from '@/utils/publicMethods';
import { defineComponent, ref } from 'vue';
import useFormData from './useFormData';
export default defineComponent({
  components: { CitySelect, ChooseMan, FileContent },
  props: {
    /** 新增编辑表单状态 1新增 2编辑 */
    formType: {
      type: [Number],
      default: 1,
    },
    data: {
      type: [Object],
    },
  },
  emits: ['close'],
  setup(props, ctx) {
    const addressData = ref();
    const isInPool = ref(0);
    let params: any = {};
    if (props.formType === 2) {
      params = Object.assign({ operateType: 2 }, props.data);
      params.leadsId = params.id;
      const data = props.data as any;
      // 设置区域
      addressData.value = [data?.provinceId, data.cityId, data.areaId];
    }
    const { rules, formData, formRef } = useFormData(params);
    // 部门组件对象
    const principalRef = ref();
    const showValue = (value) => {
      console.log(value);
    };
    const changePool = (value) => {
      formData.isInPool = value;
      if (value === 1) {
        principalRef.value.clear();
      }
      formRef.value.clearValidate('principalId');
    };
    // 获取省市区联动
    const getArea = (data) => {
      formData.addressProvince = data.province.name;
      formData.provinceId = data.province.id;
      formData.addressCity = data.city.name;
      formData.cityId = data.city.id;
      formData.addressDistrict = data.county.name;
      formData.areaId = data.county.id;
    };
    const close = (status = false) => {
      ctx.emit('close', status);
    };
    const submitData = async () => {
      const checkRes = await httpPost('/malicrm/common/checkCustomerNameActive', {
        customerName: formData.customerName,
        leadsId: formData.id,
      });
      if (checkRes.code === 200) {
        ajaxLoading.lock();
        // 转化自定义备注信息
        formData.customerRemark = formData.remark;
        const res = await httpPost('/malicrm/leads/translateLeads', formData);
        if (res.code === 200) {
          successMessage('转化成功');
          let type = 0;
          if (isInPool.value === 0) {
            type = 1;
          } else {
            type = 2;
          }
          ajaxLoading.unLock();
          loadPage('ContactDetails', {
            id: res.result,
            type, // 1客户 2客户池 3回收站
          });
          // 这里的解锁在页面返回解除掉，这样可以保证一定不会重复提交
          close(true);
        } else {
          errorMessage(res.message);
          ajaxLoading.unLock();
        }
      } else {
        errorMessage(checkRes.message);
      }
    };
    /** 提交表单 */
    const saveFormData = async () => {
      formRef.value.validate((valid) => {
        if (valid) {
          submitData();
        } else {
          return false;
        }
      });
    };
    /** 获取营业执照 */
    const getBusinessLicense = (fileList: any[]) => {
      if (fileList.length > 0) {
        formData.businessLicenseFileName = fileList[0].name;
        formData.businessLicenseFileUrl = fileList[0].url;
      } else {
        formData.businessLicenseFileName = null;
        formData.businessLicenseFileUrl = null;
      }
    };
    /** 获取法人信息 */
    const getIdCards = (fileList) => {
      formData.cardDtoList = fileList;
    };
    /** 获取开户许可证 */
    const getPermitFileName = (fileList: any[]) => {
      if (fileList.length > 0) {
        formData.openingPermitFileName = fileList[0].name;
        formData.openingPermitFileUrl = fileList[0].url;
      } else {
        formData.openingPermitFileName = null;
        formData.openingPermitFileUrl = null;
      }
    };
    return {
      close,
      formData,
      getArea,
      addressData,
      saveFormData,
      rules,
      formRef,
      isInPool,
      changePool,
      principalRef,
      getBusinessLicense,
      getIdCards,
      getPermitFileName,
      showValue,
    };
  },
});
