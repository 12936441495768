
import { httpGet, httpPost } from '@/api';
import { computed, reactive, ref, toRefs } from 'vue';
import { erpConfirm, errorMessage, getRouterParams, successMessage } from '@/utils/publicMethods';
import router from '@/router';
import BaseInfo from './components/BaseInfo/index.vue';
import ViewCommunicate from './components/ViewCommunicate/index.vue';
import ViewAttachment from './components/ViewAttachment/index.vue';
import CuleForm from './components/ClueForm/ClueForm.vue';
import TransForm from './components/TransForm/TransForm.vue';
import ViewTask from './components/ViewTask/index.vue';
import ChooseMan from '@/components/ChooseMan/ChooseMan.vue';
import useVisible from '@/hooks/useVisible';

export default {
  name: 'viewClue',
  components: {
    BaseInfo,
    ViewCommunicate,
    ViewTask,
    ViewAttachment,
    CuleForm,
    ChooseMan,
    TransForm,
  },
  props: {},
  setup() {
    // 获取路由参数
    const { id, type: paramType, backFrom } = getRouterParams('viewClue');
    // 当前列表状态
    const type = ref(paramType as number);
    const detailsData = ref();
    const load = ref(true);
    const chooseRef = ref();
    /** 编辑展示 */
    const { visible, showVisible } = useVisible();
    const { visible: transVisble, showVisible: showTransVisble } = useVisible();
    const leftTitleNames = reactive({
      ViewCommunicateTitle: '沟通记录',
      ViewTaskTitle: '任务',
      ViewEnclosureTitle: '附件',
    });

    // 是否能编辑
    const isHidden = computed(() => {
      return type.value > 1;
    });
    const scoreName = computed(() => {
      let res = detailsData.value?.customerName;
      if (detailsData.value?.contactsName) {
        res += '(' + detailsData.value?.contactsName + ')';
      }
      return res;
    });
    const closeVisible = () => {
      visible.value = false;
      transVisble.value = false;
    };
    // 返回操作
    const back = () => {
      if (backFrom) {
        router.push(backFrom);
        return;
      }
      if (paramType === 1) {
        router.push('/clue/clueList');
      }
      if (paramType === 2) {
        router.push('/clue/cluepool');
      }
      if (paramType === 3) {
        router.push('/clue/recyclebin');
      }
    };
    // 初始化数据
    const initData = async () => {
      load.value = false;
      const res = await httpGet(`/malicrm/leads/leadsDetail/${id}`);
      if (res.code === 200) {
        detailsData.value = res.result;
        load.value = true;
      } else {
        if (res.code === 9999100) {
          back();
        } else {
          errorMessage(res.message);
        }
      }
    };
    // 设置标题方法
    const getViewCommunicateTitle = (str) => {
      leftTitleNames.ViewCommunicateTitle = str;
    };
    const getViewTaskTitle = (str) => {
      leftTitleNames.ViewTaskTitle = str;
    };
    const getViewEnclosureTitle = (str) => {
      leftTitleNames.ViewEnclosureTitle = str;
    };

    // 分配线索
    const distributionLeads = () => {
      chooseRef.value.showVisible();
    };
    // 分配线索触发
    const setDistributionLeads = async (ids) => {
      const params = {
        id: detailsData.value.id,
        version: detailsData.value.version,
        principalIds: ids,
      };
      const res = await httpPost('/malicrm/leads/assignLeads', params);
      if (res.code === 200) {
        router.push('/clue/cluepool');
        successMessage('分配成功！');
      } else {
        errorMessage(res.message);
      }
    };
    // 转化
    const transformLeads = () => {
      showTransVisble();
    };
    const editLeads = () => {
      showVisible();
    };
    // 删除
    const deleteLeads = () => {
      erpConfirm('确定要删除当前线索吗？').then(async () => {
        const params = {
          id: detailsData.value.id,
          version: detailsData.value.version,
          operateType: 1,
        };
        const res = await httpPost('/malicrm/leads/deReLeads', params);
        if (res.code === 200) {
          // 有负责人id就是正常线索
          router.push('/clue/clueList');
          successMessage('已移入回收站');
        } else {
          errorMessage(res.message);
        }
      });
    };
    // 还原
    const reductionLeads = () => {
      erpConfirm('确定要还原当前线索吗？').then(async () => {
        const params = {
          id: detailsData.value.id,
          version: detailsData.value.version,
          operateType: 2,
        };
        const res = await httpPost('/malicrm/leads/deReLeads', params);
        if (res.code === 200) {
          // 有负责人id就是正常线索
          type.value = detailsData.value.principalId ? 1 : 2;
          successMessage('已还原');
          initData();
        } else {
          errorMessage(res.message);
        }
      });
    };

    initData();
    return {
      load,
      id,
      type,
      isHidden,
      detailsData,
      back,
      ...toRefs(leftTitleNames),
      getViewCommunicateTitle,
      getViewTaskTitle,
      getViewEnclosureTitle,
      deleteLeads,
      editLeads,
      transformLeads,
      distributionLeads,
      chooseRef,
      setDistributionLeads,
      reductionLeads,
      scoreName,
      visible,
      showVisible,
      closeVisible,
      showTransVisble,
      transVisble,
    };
  },
};
